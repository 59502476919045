import { render, staticRenderFns } from "./Detail.vue?vue&type=template&id=f71b71d6&scoped=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f71b71d6",
  null
  
)

export default component.exports